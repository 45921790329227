import React, { ReactNode } from 'react'
import styles from './Caption.module.scss'

interface ICaptionProps {
  children: ReactNode,
}

export default (props: ICaptionProps) => {
  return <div className={styles.caption}>{props.children}</div>;
}
