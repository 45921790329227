import React, { ReactNode } from 'react'
import styles from './Japanese.module.scss'

interface IColoredProps {
  children: ReactNode,
}

export default (props: IColoredProps) => {
  return <mark className={`${styles.mark} ${styles[`markColor_r`]}`}>{props.children}</mark>;
}
