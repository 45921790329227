import React, { ReactNode } from 'react'
import Container from 'react-bootstrap/Container'
import Footer from './Footer'
import Header from './Header'
import SecondaryNavigation from './SecondaryNavigation'
import SectionNav from './SectionNav'
import Tipping from './Tipping'

import './custom-bootstrap.scss';
import styles from './Layout.module.scss'

interface ILayoutProps {
  booklinks: any
  captions: Array<{
    title: string
    url: string
  }>
  children: ReactNode,
  location: any
}

const Layout = (props: ILayoutProps) => (<div>
  <Header />
  <Container>
    <div className={styles.articleContainer}>
        <div className={styles.secondaryNav}>
          <SecondaryNavigation booklinks={props.booklinks} location={props.location}/>
        </div>
        <div className={styles.article}>
          {props.children}
        </div>
    </div>
  </Container>
  <Tipping />
  <SectionNav className={styles.footerNavigation}/>
  <Footer />
</div>);

export default Layout;